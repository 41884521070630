<template>
	<div class="pui-form">
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<doc-new-version-modal :modelName="modelName" :pkAttribute="pkAttribute"></doc-new-version-modal>
			<v-tabs-items v-model="tabmodel">
				<v-tab-item :key="0" lazy :value="'imofalform6'">
					<v-row class="pui-form-layout">
						<v-col cols="12">
							<v-row dense style="margin-top: -15px">
								<v-col class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
									<pui-field-set :title="$t('imofalform6.fieldset.personalinfo')">
										<v-row dense>
											<!-- FAMILYNAME -->
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
												<pui-text-field
													:id="`familyname-imofalform6`"
													v-model="model.familyname"
													:label="$t('imofalform6.familyname')"
													:disabled="formDisabled"
													required
													toplabel
													maxlength="70"
												></pui-text-field>
											</v-col>
											<!-- GIVENNAME -->
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
												<pui-text-field
													:id="`givenname-imofalform6`"
													v-model="model.givenname"
													:label="$t('imofalform6.givenname')"
													:disabled="formDisabled"
													required
													toplabel
													maxlength="70"
												></pui-text-field>
											</v-col>
											<!-- NATIONALITY -->
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
												<pui-select
													:id="`nationality-imofalform6`"
													:attach="`nationality-imofalform6`"
													:label="$t('imofalform6.nationality')"
													:disabled="formDisabled"
													required
													toplabel
													clearable
													modelName="mcountries"
													v-model="model"
													reactive
													:itemsToSelect="nationalityItemsToSelect"
													:modelFormMapping="{ countrycode: 'nationality' }"
													:itemValue="['countrycode']"
													:itemText="(item) => `${item.countrycode} - ${item.countrydesc}`"
												></pui-select>
											</v-col>
											<!-- BIRTHDATE -->
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
												<pui-date-field
													:id="`birthdate-imofalform6`"
													v-model="model.birthdate"
													:label="$t('imofalform6.birthdate')"
													:disabled="formDisabled"
													required
													toplabel
													:max="getMaxDate()"
												></pui-date-field>
											</v-col>
										</v-row>
										<v-row dense style="margin-top: -15px">
											<!-- BIRTHPLACE -->
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
												<pui-text-field
													:id="`birthplace-imofalform6`"
													v-model="model.birthplace"
													:label="$t('imofalform6.birthplace')"
													:disabled="formDisabled"
													required
													toplabel
													maxlength="35"
												></pui-text-field>
											</v-col>
											<!-- GENDERCODE -->
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
												<pui-select
													:id="`gendercode-imofalform6`"
													:attach="`gendercode-imofalform6`"
													:label="$t('imofalform6.gendercode')"
													:disabled="formDisabled"
													required
													toplabel
													clearable
													modelName="mgender"
													v-model="model"
													reactive
													:itemsToSelect="gendercodeItemsToSelect"
													:modelFormMapping="{ gendercode: 'gendercode' }"
													:itemValue="['gendercode']"
													:itemText="(item) => `${item.genderdescription}`"
													:order="{ genderdescription: 'asc' }"
												></pui-select>
											</v-col>
										</v-row>
									</pui-field-set>
								</v-col>
							</v-row>
							<v-row dense>
								<v-col class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
									<pui-field-set :title="$t('imofalform6.fieldset.documentident')">
										<v-row dense>
											<!-- DOCUMENTTYPE -->
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
												<pui-select
													:id="`documenttype-imofalform6`"
													:attach="`documenttype-imofalform6`"
													:label="$t('imofalform6.documenttype')"
													:disabled="formDisabled"
													required
													toplabel
													modelName="mimofalform5typesiddoc"
													v-model="model"
													reactive
													:itemsToSelect="typesIdDocItemsToSelect"
													:modelFormMapping="{ identdocid: 'documenttype' }"
													:itemValue="['identdocid']"
													:itemText="(item) => `${item.typedescription}`"
												></pui-select>
											</v-col>
											<!-- DOCUMENTNUMBER -->
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
												<pui-text-field
													:id="`documentnumber-imofalform6`"
													v-model="model.documentnumber"
													:label="$t('imofalform6.documentnumber')"
													:disabled="formDisabled"
													required
													toplabel
												></pui-text-field>
											</v-col>
											<!-- NATIONALITY -->
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
												<pui-select
													:id="`issuingstate-imofalform6`"
													:attach="`issuingstate-imofalform6`"
													:label="$t('imofalform6.issuingstate')"
													:disabled="formDisabled"
													required
													toplabel
													clearable
													modelName="mcountries"
													v-model="model"
													reactive
													:itemsToSelect="issuingstateItemsToSelect"
													:modelFormMapping="{ countrycode: 'issuingstate' }"
													:itemValue="['countrycode']"
													:itemText="(item) => `${item.countrycode} - ${item.countrydesc}`"
												></pui-select>
											</v-col>
											<!-- EXPIRYDATE -->
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
												<pui-date-field
													:id="`expirydate-imofalform6`"
													v-model="model.expirydate"
													:label="$t('imofalform6.expirydate')"
													:disabled="formDisabled"
													required
													toplabel
												></pui-date-field>
											</v-col>
										</v-row>
									</pui-field-set>
								</v-col>
							</v-row>

							<v-row dense>
								<v-col class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
									<pui-field-set :title="$t('menufieldset.voyage')">
										<v-row dense>
											<!-- embportcountry -->
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
												<pui-select
													id="`imofalform6-embportcountry`"
													attach="imofalform6-embportcountry"
													:label="$t('imofalform6.embportcountry')"
													toplabel
													required
													:disabled="formDisabled"
													singleItemAutoselect
													v-model="model"
													modelName="mports"
													:modelFormMapping="{ countrycode: 'embcountry', portcode: 'embport' }"
													:itemsToSelect="[{ countrycode: model.embcountry, portcode: model.embport }]"
													:itemValue="['countrycode', 'portcode']"
													:itemText="(item) => `${item.locode} - ${item.portdescription}`"
													:order="{ locode: 'asc' }"
												></pui-select>
											</v-col>
											<!-- VISANUMBER -->
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
												<pui-text-field
													:id="`visanumber-imofalform6`"
													v-model="model.visanumber"
													:label="$t('imofalform6.visanumber')"
													:disabled="formDisabled"
													toplabel
													maxlength="35"
												></pui-text-field>
											</v-col>
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
												><!-- disportcountry -->
												<pui-select
													id="`imofalform6-disportcountry`"
													attach="imofalform6-disportcountry"
													:label="$t('imofalform6.disportcountry')"
													toplabel
													required
													:disabled="formDisabled"
													singleItemAutoselect
													v-model="model"
													modelName="mports"
													:modelFormMapping="{ countrycode: 'discountry', portcode: 'disport' }"
													:itemsToSelect="[{ countrycode: model.discountry, portcode: model.disport }]"
													:itemValue="['countrycode', 'portcode']"
													:itemText="(item) => `${item.locode} - ${item.portdescription}`"
													:order="{ locode: 'asc' }"
												></pui-select>
											</v-col>
											<!-- INDTRANSIT -->
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
												<pui-select
													id="`indtransit-imofalform6`"
													:label="$t('imofalform6.indtransit')"
													toplabel
													required
													:disabled="formDisabled"
													singleItemAutoselect
													v-model="model"
													:modelFormMapping="{ value: 'indtransit' }"
													:itemsToSelect="indtransitItemsToSelect"
													itemValue="value"
													itemText="text"
													:items="codes"
												></pui-select>
											</v-col>
										</v-row>
									</pui-field-set>
								</v-col>
							</v-row>

							<!-- MINI AUDIT -->
						</v-col>
					</v-row>
				</v-tab-item>
				<!-- Page Footer -->
				<pui-form-footer>
					<pui-form-footer-btns
						:formDisabled="!havePermissions(['WRITE_IMOFALFORM6'])"
						:saveDisabled="saving"
						:saveAndNew="saveAndNew"
						:saveAndUpdate="saveAndUpdate"
						:save="save"
						:back="back"
					></pui-form-footer-btns>
				</pui-form-footer>
			</v-tabs-items>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import imofalform6Actions from '../imofalform6menu/ImoFalForm6Actions';
import docNewVersionModal from '@/components/nsw-components/DocumentNewVersionModal';

export default {
	name: 'imofalform6-form',
	mixins: [PuiFormMethodsMixin],
	components: {
		DocNewVersionModal: docNewVersionModal
	},
	data() {
		return {
			modelName: 'imofalform6',
			actions: imofalform6Actions.actions,
			pkAttribute: 'imofalformid',
			parentModelName: 'imofalform6menu',
			modeldetail: true,
			tabmodel: 'imofalform6',
			labelportarrival: this.$t('imofalform6.portofarrival'),
			birthdate: null,
			visaNumberRules: [
				(v) => !v || v.length === 16 || this.$t('Visa number length error', { number: 16 }),
				(v) => !v || this.validateVisaNumber(v) || this.$t('Wrong Visa number')
			],
			codes: [
				{
					text: 'Yes',
					value: '1'
				},
				{
					text: 'No',
					value: '0'
				}
			],
			codes2: [
				{
					text: 'M1',
					value: '0'
				},
				{
					text: 'M2',
					value: '1'
				},
				{
					text: 'M3',
					value: '2'
				}
			]
		};
	},
	methods: {
		havePermissions(permissions) {
			const allowedForms = this.$store.getters.getSession.functionalities;
			// Verificar si el usuario tiene permisos para todos los permisos especificados
			return permissions.every((permission) => allowedForms.includes(permission));
		},
		afterGetData() {
			// Do something after get data from server

			const parentModel = this.$puiUtils.getPathModelMethodPk(this.$router, this.parentModelName);
			if (parentModel) {
				this.model.imofalformid = parentModel.pk.imofalformid;
			}
			if (this.$route.params.parentpk) {
				this.model.imofalformid = JSON.parse(atob(this.$route.params.pkgeneral)).imofalformid;
			}
		},
		getMaxDate() {
			const currentDate = new Date();
			const year = currentDate.getFullYear();
			const month = currentDate.getMonth() + 1; // Meses de 0 a 11
			const day = currentDate.getDate();

			// Formateamos la fecha en el formato YYYY-MM-DD
			return `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
		},
		validateVisaNumber(visaNumber) {
			const regexVisa = /^4[0-9]{15}$/; // Patrón de Visa (16 dígitos, comienza con 4)
			return regexVisa.test(visaNumber);
		}
	},
	computed: {
		nationalityItemsToSelect() {
			return [{ countrycode: this.model.nationality }];
		},
		issuingstateItemsToSelect() {
			return [{ countrycode: this.model.issuingstate }];
		},
		gendercodeItemsToSelect() {
			return [{ gendercode: this.model.gendercode }];
		},
		typesIdDocItemsToSelect() {
			return [{ identdocid: this.model.documenttype }];
		},
		indtransitItemsToSelect() {
			return [{ value: this.model.indtransit }];
		},
		vehiclecategoryToSelect() {
			return [{ value: this.model.vehiclecategory }];
		}
	},
	watch: {},
	created() {},
	updated() {
		if (this.model.indtransit == null) {
			this.model.indtransit = '0';
		}
	}
};
</script>
